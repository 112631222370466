/*html{*/
/*  zoom: 2;*/
/*}*/
label {
  font-weight: 500 !important;
}

/*.ant-input {*/
/*  height: 30px !important;*/
/*  background-color: #F9F9F9 !important;*/
/*}*/

/*.ant-input-affix-wrapper,*/
/*.ant-select:not(.ant-select-customize-input) .ant-select-selector {*/
/*  background-color: #F9F9F9 !important;*/
/*  border: unset !important;*/
/*}*/

/*.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {*/
/*  height: 40px !important;*/
/*}*/

/*.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {*/
/*  line-height: 40px !important;*/
/*}*/

/*.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {*/
/*  height: 40px !important;*/
/*}*/

/*.link-column,*/
/*.link-column a{*/
/*     color: #333333;*/
/*    font-weight: 500;*/
/*}*/
/*table td:hover .link-column,*/
/*table td:hover .link-column a{*/
/*  text-decoration: none;*/
/*  color: #056EE9;*/
/*}*/
